.App {
    text-align: center;
  }
  
  #App-logo {
    height: 7vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    #App-logo {
      animation: App-logo-spin infinite 10s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  