.StripeFormGroup {
	margin: 20px 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
}

.StripeFormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 1px 10px;
	border-top: 2px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	margin: 10px 0;
	
}

@media only screen and (min-width: 600px) {
	.StripeElement{
		width: 60%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}

@media only screen and (min-width: 768px) {
	.StripeElement{
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}

@media only screen and (min-width: 1100px) {
	.StripeElement{
		width: 40%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}

@media only screen and (min-width: 1500px) {
	.StripeElement{
		width: 30%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}

@media only screen and (min-width: 2000px) {
	.StripeElement{
		width: 20%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}


.StripeButton {
	display: block;
	font-size: 16px;
	width: calc(80% - 30px);
	height: 40px;
	margin: auto;
	background-color: #2e14c0;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

@media only screen and (min-width: 600px) {
	.StripeButton{
		width: 40%;
	}
}

@media only screen and (min-width: 1100px) {
	.StripeButton{
		width: 25%;
	}
}

@media only screen and (min-width: 1500px) {
	.StripeButton{
		width: 20%;
	}
}

@media only screen and (min-width: 2000px) {
	.StripeButton{
		width: 15%;
	}
}


.StripeButton:active {
	background-color: #1c61a1;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}

/* .App {
	margin-left: 25%;
	margin-top: 5%;
	margin-right: 25%;
} */

/* h1,
h3 {
	text-align: center;
} */